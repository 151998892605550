import React, {useEffect, useState} from 'react';
import '../App.css';
import Cookies from "universal-cookie";
import Header from "./Header";
import axios from "axios";
import { SpinnerComponent } from 'react-element-spinner';
import {Link} from "react-router-dom";
import Sidebar from "./Sidebar";
import Footer from "./Footer";

function LogGroups() {
    const [groups, setGroups] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const cookies = new Cookies();
        const session_id = cookies.get('admin_session_id');

        const fetchData = async () => {
            setLoading(true);
            const feedData = await axios
                .get(`${process.env.REACT_APP_API_DOMAIN}/api/log/group`, {
                    withCredentials: true,
                    headers: {
                        Authorization: session_id
                    }
                });


            if(feedData.data) {
                setGroups(feedData.data.logGroups);
            }

            setLoading(false);
        };

        void fetchData();
    }, []);

    const rows = groups.map((group, k) => <li><Link to={"/loggroups/" + btoa(group.logGroupName)}>{group.logGroupName}</Link></li>);

    return (
        <>
            <Header />
            <SpinnerComponent loading={loading} position="global" color="#498DFF" message={"Sit tight"}/>
            <div className="d-flex align-items-stretch">
                <Sidebar />
                <div className="page-content">
                    <div className="container-fluid py-2" style={{color: '#65676b'}}>
                        <Link to={"/home"}>Home</Link> > Log Groups
                    </div>
                    <div className="container-fluid mx-4 p-3" style={{background: '#2d3035', color: '#85888e'}}>
                        <h2>Log groups</h2>
                        Logs for all environments. MyStack: Dev Environment
                        <ul>
                            {rows}
                        </ul>
                    </div>
                    <Footer />
                </div>
            </div>
        </>
    );
}
export default LogGroups;

