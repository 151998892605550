import React, {useState} from 'react';
import '../App.css';
import Header from './Header';
import Sidebar from './Sidebar';
import Footer from './Footer';
import {Link} from "react-router-dom";
import axios from "axios";
import {SpinnerComponent} from "react-element-spinner";
import Cookies from "universal-cookie";



function SendScript() {

    const [isDisabled, setDisabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const [request, setRequest] = useState({
        subject: '',
        message: '',
        messagetype: 'SCRIPT',
        sender: 'Agency Next Staff'
    });


    const onSubmit = async (e) => {
        const cookies = new Cookies();
        const session_id = cookies.get('admin_session_id');

        setLoading(true);
        e.preventDefault();
        setDisabled(true);

        await axios.put(`${process.env.REACT_APP_API_DOMAIN}/api/message/`, request, {
            withCredentials: true,
            headers: {
                Authorization: session_id
            }
        } ).catch(function (error) {
            setError('There was an error sending the script');
        });

        setDisabled(false);
        setLoading(false);
    }

    const onChange = (e) => {
        setRequest({...request, [e.target.name]: e.target.value});
    };

    return (
        <>
            <Header />
            <SpinnerComponent loading={loading} position="global" color="#498DFF" message={"Sit tight"}/>
            <div className="d-flex align-items-stretch">
                <Sidebar />
                <div className="page-content">
                    <div className="bg-dash-dark-2 py-4">
                        <div className="container-fluid" style={{color: '#7d8087'}}>
                            <Link to={"/home"}>Home</Link> > Generate Introduction
                        </div>
                    </div>
                    <div className="container-fluid m-3 p-3" style={{background: '#2d3035', color: '#85888e'}}>
                        <div>{error}</div>

                        <form onSubmit={onSubmit} className="justify-content-center">
                            <SpinnerComponent loading={loading} position="global" color="#498DFF" message={"Sit tight"}/>
                            <div className="form-group">
                                <label>Subject</label><br/><br/>
                                <input type="text" name="subject" className="form-control" onChange={onChange} disabled={isDisabled}/>
                            </div>
                            <div className="form-group">
                                <label className="sr-only">Script</label><br/><br/>
                                <textarea type="text" name="message" className="form-control" onChange={onChange} disabled={isDisabled}/>
                            </div>

                            <button type="submit" className="btn btn-primary btn-lg" disabled={isDisabled}>Send Script to all Customers</button>
                        </form>
                    </div>
                    <Footer />
                </div>
            </div>
        </>
    );
}
export default SendScript;

