import React, {useEffect, useState} from 'react';
import '../App.css';
import Header from './Header';
import Sidebar from './Sidebar';
import Footer from './Footer';
import Cookies from "universal-cookie";
import axios from "axios";
import {SpinnerComponent} from "react-element-spinner";
import {Link} from "react-router-dom";
import BarChart  from "../widgets/BarChart";
import moment from "moment/moment";

function Home() {

    const [months, setMonths] = useState([]);
    const [signups, setSignups] = useState([]);
    const [files, setFiles] = useState([]);
    const [filesUpdated, setFilesUpdated] = useState([]);
    const [filesDrafts, setFilesDrafts] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const cookies = new Cookies();
        const session_id = cookies.get('admin_session_id');

        const fetchData = async () => {
            setLoading(true);
            const dashData = await axios
                .get(`${process.env.REACT_APP_API_DOMAIN}/api/dash`, {
                    withCredentials: true,
                    headers: {
                        Authorization: session_id
                    }
                });

            if(dashData.data.new_users) {
                setCustomers(dashData.data.new_users);
            }

            if(dashData.data.new_media) {
                setFiles(dashData.data.new_media);
            }

            if(dashData.data.new_media_updated) {
                setFilesUpdated(dashData.data.new_media_updated);
            }

            if(dashData.data.oldest_drafts) {
                setFilesDrafts(dashData.data.oldest_drafts);
            }

            if(dashData.data.signups) {
                setMonths(dashData.data.signups.map(m => m._month));
                setSignups(dashData.data.signups.map(m => m._count));
            }

            setLoading(false);
        };

        void fetchData();

    }, []);

    const new_users = customers.map((customer, k) => <tr><td><Link to={"/customer/" + customer._id}>{customer._next_id}</Link></td><td>{customer._first} {customer._last}<br />{customer._email}</td><td>{moment(customer._created).local().format("MM/DD/YYYY HH:mm:ss")}</td></tr>);
    const new_files = files.map((file, k) => <tr><td><Link to={"/file/" + file._id}>{file._filename}</Link></td><td>{file._status}</td><td>{moment(file._created).local().format("MM/DD/YYYY HH:mm:ss")}</td></tr>);
    const updated_files = filesUpdated.map((file, k) => <tr><td><Link to={"/file/" + file._id}>{file._filename}</Link></td><td>{file._status}</td><td>{moment(file._updated).local().format("MM/DD/YYYY HH:mm:ss")}</td></tr>);
    const oldest_drafts = filesDrafts.map((file, k) => <tr><td><Link to={"/file/" + file._id}>{file._filename}</Link></td><td>{moment(file._created).local().format("MM/DD/YYYY HH:mm:ss")}</td></tr>);

    return (
        <>
            <Header />
            <SpinnerComponent loading={loading} position="global" color="#498DFF" message={"Sit tight"}/>
            <div className="d-flex align-items-stretch">
                <Sidebar />
                <div className="page-content">
                    <div className="bg-dash-dark-2 py-4">
                        <div className="container-fluid">
                            <h2 className="h5 mb-0" style={{color: '#7d8087'}}>Dashboard</h2>
                        </div>
                    </div>
                    <div className="container-fluid col-md-10 m-3 p-3" style={{background: '#2d3035', color: '#85888e'}}>
                        <div className="row">
                            <div className="col-md-5 ml-3 mb-3 mr-3">
                                <h5>Customers By Month</h5>
                                <BarChart title={"Customers"} values={signups} labels={months} />
                            </div>
                            <div className="col-md-5 ml-3 mb-3 mr-3">
                                <h5>Newest Customers</h5>
                                <table className={"table"}>
                                    <tr><th>Next Id</th><th>Name</th><th>Added</th></tr>
                                        {new_users}
                                 </table>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-5 ml-3 mb-3 mr-3">
                                <h5>New Files</h5>
                                <table className={"table"}>
                                    <tr><th>Filename</th><th>Status</th><th>Created</th></tr>
                                    {new_files}
                                </table>
                            </div>
                            <div className="col-md-5 ml-3 mb-3 mr-3">
                                <h5>Updated Files</h5>
                                <table className={"table"}>
                                    <tr><th>Filename</th><th>Status</th><th>Updated</th></tr>
                                    {updated_files}
                                </table>
                            </div>
                            <div className="col-md-5 ml-3 mb-3 mr-3">
                                <h5>Oldest Drafts</h5>
                                Customers who have not acknowledged their drafts
                                <table className={"table"}>
                                    <tr><th>Filename</th><th>Added</th></tr>
                                    {oldest_drafts}
                                </table>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </>
    );
}
export default Home;

