import React, {useEffect, useState} from 'react';
import '../App.css';
import Cookies from "universal-cookie";
import Header from "./Header";
import axios from "axios";
import { SpinnerComponent } from 'react-element-spinner';
import {Link, useParams} from "react-router-dom";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import moment from "moment/moment";

function Files() {
    const [files, setFiles] = useState([]);
    const [loading, setLoading] = useState(false);
    const {id} = useParams();

    useEffect(() => {
        const cookies = new Cookies();
        const session_id = cookies.get('admin_session_id');

        const fetchData = async () => {
            setLoading(true);
            const fileData = await axios
                .get(`${process.env.REACT_APP_API_DOMAIN}/api/media/all/${id}`, {
                    withCredentials: true,
                    headers: {
                        Authorization: session_id
                    }
                });
            if(fileData.data && fileData.data.length > 0) {
                setFiles(fileData.data);
            }

            setLoading(false);
        };

        void fetchData();


    }, [id]);

    const rows = files.map((file, k) => <tr><td><Link to={"/file/" + file._id}>{file._filename}</Link></td><td>{file._status}</td><td>{ file._size ? (file._size / 1048576).toFixed(3) : 0} MB</td><td>{moment(file._updated).local().format("MM/DD/YYYY HH:mm:ss")}</td><td>{file._archived}</td><td><Link to={"/download/" + file._id} target={"_blank"}>Download</Link></td></tr>);

    return (
        <>
            <Header />
            <SpinnerComponent loading={loading} position="global" color="#498DFF" message={"Sit tight"}/>
            <div className="d-flex align-items-stretch">
                <Sidebar />
                <div className="page-content">
                    <div className="container-fluid py-2" style={{color: '#7d8087'}}>
                        <Link to={"/home"}>Home</Link> > <Link to={"/customers"}>Customers</Link> > <Link to={"/customer/" + id}>Customer</Link> > Files
                    </div>
                    <div className="container-fluid m-3 p-3" style={{background: '#2d3035', color: '#85888e'}}>
                        <table className="table">
                            <tr><th>File Name</th><th>Status</th><th>Size</th><th>Last Updated</th><th>Archived?</th><th></th></tr>
                            {rows}
                        </table>
                    </div>
                    <Footer />
                </div>
            </div>
        </>
    );
}
export default Files;

