import React, {useEffect} from 'react';
import { useNavigate} from 'react-router-dom';
import axios from "axios";
import Cookies from "universal-cookie";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";


const Header = () => {
    const navigate = useNavigate();

    useEffect(() => {

        const cookies = new Cookies();
        const session_id = cookies.get('admin_session_id');

        if (!session_id) {
            navigate('/login');
            return;
        }
        (async () => {
            await axios
            .get(`${process.env.REACT_APP_API_DOMAIN}/api/adminsession/`,
                {
                    withCredentials: true,
                    headers: {
                        Authorization: session_id
                    }
                })
            .then((res) => {

            })
            .catch(function () {
                navigate('/login');
            });
        })();
    }, [navigate]);



    return (
        <header className="header">
            <nav className="navbar navbar-expand-lg py-3 bg-dash-dark-2 border-bottom border-dash-dark-1 z-index-10">
                <div className="container-fluid d-flex align-items-center justify-content-between py-1">
                    <div className="navbar-header d-flex align-items-center">
                        <a className="navbar-brand text-uppercase text-reset" href="/home">
                            <div className="brand-text brand-big"><strong className="text-success" style={{color: '#4ea309'}}>Agency Next Admin</strong><span className="text-secondary" style={{color: '#094EA3'}}>[{process.env.REACT_APP_ENVIRONMENT}]</span></div>
                        </a>
                    </div>
                </div>
            </nav>
        </header>
    );

}
export default Header;
