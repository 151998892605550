import {useEffect} from 'react';
import {useParams} from 'react-router-dom';
import axios from "axios";
import Cookies from "universal-cookie";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";

function Download() {
    const { id } = useParams();

    useEffect(() => {

        const cookies = new Cookies();
        const session_id = cookies.get('admin_session_id');
        const fetchData = async () => {
            await axios
                .get(`${process.env.REACT_APP_API_DOMAIN}/api/awsurl/${id}`, {
                    withCredentials: true,
                    headers: {
                        Authorization: session_id
                    }
                })
                .then((res) => {
                    window.location.href = res.data.url;
                });

        };
        void fetchData();
    }, [id]);
}
export default Download;
