import React, {useState} from 'react';
import '../App.css';
import {Link, useParams} from "react-router-dom";
import Cookies from "universal-cookie";
import axios from "axios";
import { useDropzone } from 'react-dropzone';
import { SpinnerComponent } from 'react-element-spinner';
import { ToastContainer, toast, Bounce } from 'react-toastify';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import 'react-toastify/dist/ReactToastify.css';
import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";

function Upload() {
    const [loading, setLoading] = useState(false);
    const cookies = new Cookies();
    const session_id = cookies.get('admin_session_id');
    const {id} = useParams();

    const uploadFiles = (acceptedFiles) => {

        for (let i = 0; i < acceptedFiles.length; i++) {
            (async () => {
                const file = acceptedFiles[i];
                setLoading(true);
                const presignedData = await axios
                    .post(`${process.env.REACT_APP_API_DOMAIN}/api/awsurl`,{
                        'fileName' : file.name,
                        'fileType' : file.type,
                        'userid' : id
                    }, {
                        withCredentials: true,
                        headers: {
                            Authorization: session_id
                        }
                    });

                if(presignedData.data.url) {
                    const newFile = new File([file], presignedData.data.fileName, {
                        type: file.type,
                    });

                    await axios.put(presignedData.data.url, newFile, {
                        headers: {
                            "Content-Type": newFile.type,
                        },
                    });

                    await axios.post(`${process.env.REACT_APP_API_DOMAIN}/api/media/`, {
                            'fileName' : presignedData.data.fileName,
                            'userid' : id,
                            'size' : file.size
                        },
                        {
                            withCredentials: true,
                            headers: {
                                Authorization: session_id
                            }
                        }).then(() => {
                            setLoading(false);
                            toast("Your file has been uploaded");
                        }).catch((err) => {
                            setLoading(false);
                            toast("There was an error uploading your file");
                        });
                }
            })();
        }
    }

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop: uploadFiles,
        multiple: false,
    });

    return (
        <>
            <Header />
            <SpinnerComponent loading={loading} position="global" color="#498DFF" message={"Sit tight"}/>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
                transition={Bounce}
            />
            <div className="d-flex align-items-stretch">
                <Sidebar />
                <div className="page-content">
                    <div className="container-fluid py-2" style={{color: '#7d8087'}}>
                        <Link to={"/home"}>Home</Link> > <Link to={"/customers"}>Customers</Link> > <Link to={"/customer/" + id}>Customer</Link> > Send File
                    </div>
                    <div className="container-fluid m-3 p-3" style={{background: '#2d3035', color: '#85888e'}}>
                        <p>Send new file to customer</p>
                        <section className="container">
                            <div className="upload-container">
                                <div {...getRootProps()} className={`dropzone dz-message ${isDragActive ? 'active' : ''} p-4 border border-2 border-primary-subtle rounded bg-light justify-content-center`}>
                                    <input {...getInputProps()} />
                                    {isDragActive ? (<p>Drop the file here</p>) : (<p><FontAwesomeIcon className="info-icon" icon={faCircleInfo} /> Drag and drop file here or click to browse</p>)}
                                </div>
                            </div>
                        </section>
                    </div>
                    <Footer />
                </div>
            </div>
        </>
    );
}
export default Upload;

