import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

function BarChart ({title, labels, values}) {

  ChartJS.register(CategoryScale,LinearScale,BarElement,Title,Tooltip,Legend);

  const data = {
    labels: labels,
    datasets: [
      {
        label: title,
        backgroundColor: "#a3098c",
        borderColor: "#000000",
        data: values,
      },
    ],
  };


  return (
      <div>
        <Bar data={data} />
      </div>
  );
}
export default BarChart;
