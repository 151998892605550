import React, {useState} from 'react';
import '../App.css';
import Header from './Header';
import Sidebar from './Sidebar';
import Footer from './Footer';
import {Link} from "react-router-dom";
import axios from "axios";
import {SpinnerComponent} from "react-element-spinner";
import Cookies from "universal-cookie";



function GenerateIntro() {

    const [intro, setIntro] = useState('');
    const [isDisabled, setDisabled] = useState(false);
    const [loading, setLoading] = useState(false);

    const [request, setRequest] = useState({
        blob: 'Write a website introductory paragraph for Koby, a Mortgage Broker from Milwaukee, Wisconsin. The paragraph should be first person and describe how Koby is helpful, knowledgeable, and dedicated.'
    });


    const onSubmit = async (e) => {
        const cookies = new Cookies();
        const session_id = cookies.get('admin_session_id');

        setLoading(true);
        e.preventDefault();
        setDisabled(true);

        const text = await axios.post(`${process.env.REACT_APP_API_DOMAIN}/api/generate/text`, request, {
            withCredentials: true,
            headers: {
                Authorization: session_id
            }
        } );
        if(text.data) {
            setIntro(text.data.introduction)
        }

        setLoading(false);
    }

    const onChange = (e) => {
        setRequest({...request, [e.target.name]: e.target.value});
    };

    return (
        <>
            <Header />
            <SpinnerComponent loading={loading} position="global" color="#498DFF" message={"Sit tight"}/>
            <div className="d-flex align-items-stretch">
                <Sidebar />
                <div className="page-content">
                    <div className="bg-dash-dark-2 py-4">
                        <div className="container-fluid" style={{color: '#7d8087'}}>
                            <Link to={"/home"}>Home</Link> > Generate Introduction
                        </div>
                    </div>
                    <div className="container-fluid m-3 p-3" style={{background: '#2d3035', color: '#85888e'}}>
                            <strong>Please be patient, it takes a while.</strong>

                        <form onSubmit={onSubmit} className="justify-content-center">
                            <SpinnerComponent loading={loading} position="global" color="#498DFF" message={"Sit tight"}/>
                            <div className="form-group">
                                <label className="sr-only">Name</label><br/><br/>
                                <textarea name="blob" className="form-control" placeholder="Write a website introductory paragraph for Koby, a Mortgage Broker from Milwaukee, Wisconsin. The paragraph should be first person and describe how Koby is helpful, knowledgeable, and dedicated." onChange={onChange} disabled={isDisabled}/>
                            </div>

                            <button type="submit" className="btn btn-primary btn-lg" disabled={isDisabled}>Generate</button>
                        </form>

                        <h5>Generated text</h5>
                        {intro}

                    </div>
                    <Footer />
                </div>
            </div>
        </>
    );
}
export default GenerateIntro;

