import React, {useEffect, useState} from 'react';
import '../App.css';
import Header from "./Header";
import { SpinnerComponent } from 'react-element-spinner';
import {Link, useParams} from "react-router-dom";
import Cookies from "universal-cookie";
import axios from "axios";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import moment from "moment/moment";

function File() {
    const [file, setFile] = useState({
        '_filename': '',
        '_userid': '',
        '_status': '',
        '_created': '',
        '_updated': '',
        '_formatted_created': '',
        '_formatted_updated': '',
        '_archived': '',
    })
    const [loading, setLoading] = useState(false);
    const {id} = useParams();

    useEffect(() => {
        const cookies = new Cookies();
        const session_id = cookies.get('admin_session_id');

        const fetchData = async () => {
            setLoading(true);
            const fileData = await axios
                .get(`${process.env.REACT_APP_API_DOMAIN}/api/media/${id}`, {
                    withCredentials: true,
                    headers: {
                        Authorization: session_id
                    }
                });
            setFile(fileData.data);
            setLoading(false);
        };

        void fetchData();


    }, [id]);



    return (
        <>
            <Header />
            <SpinnerComponent loading={loading} position="global" color="#498DFF" message={"Sit tight"}/>
            <div className="d-flex align-items-stretch">
                <Sidebar />
                <div className="page-content">
                    <div className="container-fluid py-2" style={{color: '#7d8087'}}>
                        <Link to={"/home"}>Home</Link> > <Link to={"/customers"}>Customers</Link> > <Link to={"/customer/" + file._userid}>Customer</Link> > <Link to={"/files/" + file._userid}>Files</Link> > File
                    </div>
                    <div className="container-fluid m-3 p-3" style={{background: '#2d3035', color: '#85888e'}}>
                        <table className={"table"}>
                            <tr><th>Filename:</th><td>{file._filename}</td></tr>
                            <tr><th>Status:</th><td>{file._status}</td></tr>
                            <tr><th>Size:</th><td>{file._size ? (file._size / 1048576).toFixed(3) : 0} MB</td></tr>
                            <tr><th>Created:</th><td>{moment(file._created).local().format("MM/DD/YYYY HH:mm:ss")}</td></tr>
                            <tr><th>Updated:</th><td>{moment(file._updated).local().format("MM/DD/YYYY HH:mm:ss")}</td></tr>
                            <tr><th>Archived?</th><td>{file._archived}</td></tr>
                        </table>
                    </div>
                    <Footer />
                </div>
            </div>
        </>
    );
}
export default File;

