import React, {useEffect, useState} from 'react';
import '../App.css';
import Cookies from "universal-cookie";
import Header from "./Header";
import {SpinnerComponent} from 'react-element-spinner';
import {Link, useParams} from 'react-router-dom';
import axios from "axios";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast, Bounce } from 'react-toastify';
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import moment from "moment/moment";

function Customer() {
    const [customer, setCustomer] = useState({
        _id: '',
        _first: '',
        _last: '',
        _next_id: '',
        _password: '',
        _email: '',
        _active: '',
        _phone: '',
        _phone_extension: '',
        _industry_identifier: '',
        _billing_type: '',
        _billing_id: '',
        _duda_id: '',
        _advertising_id: '',
        _google_analytics_id: '',
        _created: '',
        _updated: '',
        _formatted_created: '',
        _formatted_updated: '',
        _failed_logins: '',
        _locked_until: '',
        _timezone: '',
        _email_verified: '',
        _lead_source: '',
    });
    const [loading, setLoading] = useState(false);
    const [impersonating, setImpersonating] = useState(false);
    const [passwordReset, setPasswordReset] = useState(false);
    const [emailSent, setEmailSent] = useState(false);
    const [message, setMessage] = useState('');
    const [subject, setSubject] = useState('');
    const {id} = useParams();

    useEffect(() => {
        const cookies = new Cookies();
        const session_id = cookies.get('admin_session_id');

        const fetchData = async () => {
            setLoading(true);
            const data = await axios
                .get(`${process.env.REACT_APP_API_DOMAIN}/api/user/${id}`, {
                    withCredentials: true,
                    headers: {
                        Authorization: session_id
                    }
                });
            setCustomer(data.data);
            setLoading(false);
        };
        void fetchData();


    }, [id]);

    const impersonate = async (e) => {
        setLoading(true);
        const cookies = new Cookies();
        const session_id = cookies.get('admin_session_id');

        await axios.put(`${process.env.REACT_APP_API_DOMAIN}/api/session/` + id,{}, {
                withCredentials: true,
                headers: {
                    Authorization: session_id
                }
            }).then((res) => {
                cookies.set('session_id', res.data.session_id, {path: '/', domain: process.env.REACT_APP_EXPERIMENT_COOKIE_DOMAIN});
                setImpersonating(true);
                setLoading(false);
            });

    }

    const resetPassword = async (e) => {
        setLoading(true);
        const cookies = new Cookies();
        const session_id = cookies.get('admin_session_id');

        await axios.post(`${process.env.REACT_APP_API_DOMAIN}/api/reset/force/` + id,{}, {
            withCredentials: true,
            headers: {
                Authorization: session_id
            }
        });
        setPasswordReset(true);
        setLoading(false);
        toast("Password has been reset");
    }

    const sendWelcomeEmail = async (e) => {
        setLoading(true);
        const cookies = new Cookies();
        const session_id = cookies.get('admin_session_id');

        await axios.post(`${process.env.REACT_APP_API_DOMAIN}/api/register/welcome/` + id,{}, {
            withCredentials: true,
            headers: {
                Authorization: session_id
            }
        });
        setLoading(false);
        toast("Welcome Email sent");
        setEmailSent(true);
    }

    const onSubmit = async (e) => {
        setLoading(true);
        e.preventDefault();

        const cookies = new Cookies();
        const session_id = cookies.get('admin_session_id');

        await axios.put(`${process.env.REACT_APP_API_DOMAIN}/api/message/`,
            {
                'message': message,
                'subject': subject,
                'userid' : id,
                'sender': 'Agency Next Staff',
                'messagetype': 'MESSAGE'
            }, {
                withCredentials: true,
                headers: {
                    Authorization: session_id
                }
            });
        setMessage("");
        setSubject("");
        toast("Message sent");
        setLoading(false);
    }

    return (
        <>
            <Header />
            <SpinnerComponent loading={loading} position="global" color="#498DFF" message={"Sit tight"}/>
            <div className="d-flex align-items-stretch">
                <Sidebar />
                <div className="page-content">
                    <div className="container-fluid py-2" style={{color: '#7d8087'}}>
                        <Link to={"/home"}>Home</Link> > <Link to={"/customers"}>Customers</Link> > Customer
                    </div>
                    <div className="container-fluid m-3 p-3" style={{background: '#2d3035', color: '#85888e'}}>
                        <SpinnerComponent loading={loading} position="global" color="#498DFF" message={"Sit tight"}/>
                        <ToastContainer
                            position="top-center"
                            autoClose={5000}
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                            theme="dark"
                            transition={Bounce}
                        />
                        <h2>Customer Information</h2>
                        { impersonating ? <Link to={'https://' + process.env.REACT_APP_PORTAL_DOMAIN } target={"_blank"}>Impersonate</Link> : <button type="button" className="btn mr-2 btn-primary" onClick={impersonate}>Get Impersonate Link</button> }<br/>

                        <button type="button" className="btn mr-2 btn-primary"  disabled={passwordReset} onClick={resetPassword}>Reset Password (*)</button><br/>
                        <button type="button" className="btn mr-2 btn-primary" disabled={emailSent} onClick={sendWelcomeEmail}>Send Welcome Email(*)</button><br/>
                        (*) Be careful with these, they happen immediately and there is no reversing them.<br />

                        <table className={"table"}>
                            <tr><td>Files</td><td><Link to={"/files/" + customer._id}>View</Link> <Link to={"/upload/" + customer._id}>Send New</Link></td></tr>
                            <tr><td>Next Id</td><td>{customer._next_id}</td></tr>
                            <tr><td>First</td><td>{customer._first}</td></tr>
                            <tr><td>Last</td><td>{customer._last}</td></tr>
                            <tr><td>Email</td><td>{customer._email}</td></tr>
                            <tr><td>Phone</td><td>{customer._formatted_phone}</td></tr>
                            <tr><td>Phone Ext</td><td>{customer._phone_extension}</td></tr>
                            <tr><td>Lead Source</td><td>{customer._lead_source}</td></tr>
                            <tr><td>Active</td><td>{customer._active}</td></tr>
                            <tr><td>Duda Id</td><td>{customer._duda_id}</td></tr>
                            <tr><td>Advertising Id</td><td>{customer._advertising_id}</td></tr>
                            <tr><td>Google Analytics Id</td><td>{customer._google_analytics_id}</td></tr>
                            <tr><td>Created Date</td><td>{moment(customer._created).local().format("MM/DD/YYYY HH:mm:ss")}</td></tr>
                            <tr><td>Updated Date</td><td>{moment(customer._updated).local().format("MM/DD/YYYY HH:mm:ss")}</td></tr>
                            <tr><td>Failed Login Count</td><td>{customer._failed_logins}</td></tr>
                            <tr><td>Locked Until</td><td>{moment(customer._locked_until).local().format("MM/DD/YYYY HH:mm:ss")}</td></tr>
                            <tr><td>Timezone</td><td>{customer._timezone}</td></tr>
                            <tr><td>Email Verified?</td><td>{customer._email_verified}</td></tr>
                        </table>

                        <h2>Message</h2>
                        <form onSubmit={onSubmit}>
                            <input className="form-control"  type={"text"} onChange={e => setSubject(e.target.value)}  value={subject} placeholder="Subject" /><br />
                            <textarea class="form-control" rows={"8"} value={message} onChange={e => setMessage(e.target.value)}  /><br />
                            <button type={"submit"}>Send Message</button>
                        </form>
                    </div>
                    <Footer />
                </div>
            </div>
        </>
    );
}

export default Customer;


