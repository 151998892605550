import React, {useEffect, useState} from 'react';
import '../App.css';
import Cookies from "universal-cookie";
import Header from "./Header";
import axios from "axios";
import { SpinnerComponent } from 'react-element-spinner';
import {Link, useParams} from "react-router-dom";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
const moment = require("moment");

function LogStreams() {
    const [streams, setStreams] = useState([]);
    const [loading, setLoading] = useState(false);
    const {id} = useParams();

    useEffect(() => {
        const cookies = new Cookies();
        const session_id = cookies.get('admin_session_id');

        const fetchData = async () => {
            setLoading(true);
            const feedData = await axios
                .get(`${process.env.REACT_APP_API_DOMAIN}/api/log/group/` + id, {
                    withCredentials: true,
                    headers: {
                        Authorization: session_id
                    }
                });


            if(feedData.data) {
                setStreams(feedData.data.logStreams);
            }

            setLoading(false);
        };

        void fetchData();
    }, [id]);

    const rows = streams.map((stream, k) => <tr><td><Link to={"/stream/" + id + '/' + btoa(stream.logStreamName)}>{stream.logStreamName}</Link></td><td>{moment(stream.lastEventTimestamp, "x").local().format("MM/DD/YYYY HH:mm:ss")}</td></tr>);

    return (
        <>
            <Header />
            <SpinnerComponent loading={loading} position="global" color="#498DFF" message={"Sit tight"}/>
            <div className="d-flex align-items-stretch">
                <Sidebar />
                <div className="page-content">
                    <div className="container-fluid py-2" style={{color: '#65676b'}}>
                        <Link to={"/home"}>Home</Link> > <Link to={"/loggroups"}>Log Groups</Link> > Log Streams
                    </div>
                    <div className="container-fluid mx-4 p-3" style={{background: '#2d3035', color: '#85888e'}}>
                        <h2>Log streams</h2>
                        <table className="table">
                            <tr>
                                <th scope="col">
                                    Stream Name
                                </th>
                                <th scope="col">
                                    Last Event time
                                </th>
                            </tr>
                            {rows}
                        </table>
                    </div>
                    <Footer />
                </div>
            </div>
        </>
    );
}
export default LogStreams;

