import React from 'react';
import '../App.css';
import Header from './Header';
import Sidebar from './Sidebar';
import Footer from './Footer';



function Template() {


    return (
        <>
            <Header />
            <div className="d-flex align-items-stretch">
                <Sidebar />
                <div className="page-content">
                    <div className="bg-dash-dark-2 py-4">
                        <div className="container-fluid" style={{color: '#7d8087'}}>
                            <h2 className="h5 mb-0" style={{color: '#7d8087'}}>Dashboard</h2>
                        </div>
                    </div>
                    <div className="container-fluid m-3 p-3" style={{background: '#2d3035', color: '#85888e'}}>
                        Testing
                    </div>
                    <Footer />
                </div>
            </div>
        </>
    );
}
export default Template;

