import React, {useEffect, useState} from 'react';
import '../App.css';
import Cookies from "universal-cookie";
import Header from "./Header";
import axios from "axios";
import { SpinnerComponent } from 'react-element-spinner';
import {Link} from "react-router-dom";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import moment from "moment";

function Customers() {
    const [customers, setCustomers] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const cookies = new Cookies();
        const session_id = cookies.get('admin_session_id');

        const fetchData = async () => {
            setLoading(true);
            const feedData = await axios
                .get(`${process.env.REACT_APP_API_DOMAIN}/api/user`, {
                    withCredentials: true,
                    headers: {
                        Authorization: session_id
                    }
                });


            if(feedData.data && feedData.data.length > 0) {
                setCustomers(feedData.data);
            }

            setLoading(false);
        };

        void fetchData();


    }, []);

    const rows = customers.map((customer, k) => <tr><td><Link to={"/customer/" + customer._id}>{customer._next_id}</Link></td><td>{customer._first} {customer._last}</td><td><Link href={"mailto:" + customer._email }>{customer._email}</Link></td><td><Link href={"tel:" + customer._phone }>{customer._formatted_phone}</Link></td><td>{moment(customer._created).local().format("MM/DD/YYYY HH:mm:ss")}</td><td>{moment(customer._updated).local().format("MM/DD/YYYY HH:mm:ss")}</td></tr>);

    return (
        <>
            <Header />
            <SpinnerComponent loading={loading} position="global" color="#498DFF" message={"Sit tight"}/>
            <div className="d-flex align-items-stretch">
                <Sidebar />
                <div className="page-content">
                    <div className="container-fluid py-2" style={{color: '#65676b'}}>
                        <Link to={"/home"}>Home</Link> > Customers
                    </div>
                    <div className="container-fluid mx-4 p-3" style={{background: '#2d3035', color: '#85888e'}}>
                        <h2>All Customers</h2>
                        <table className={"table"}>
                            <tr><th>Next Id</th><th>Name</th><th>Email</th><th>Phone</th><th>Created</th><th>Updated</th></tr>
                            {rows}
                        </table>
                    </div>
                    <Footer />
                </div>
            </div>
        </>
    );
}
export default Customers;

