import React from 'react';
import '../App.css';

function Footer() {


    return (
        <>
            <footer className="position-absolute bottom-0 bg-dash-dark-2 text-white text-center py-3 w-100 text-xs" id="footer">
                <div className="container-fluid text-center">
                    <p className="mb-0 text-dash-gray">2024 &copy; Agency Next</p>
                </div>
            </footer>
        </>
        );
}
export default Footer;
