import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './components/Login';
import Home from './components/Home';
import Customers from './components/Customers';
import Customer from './components/Customer';
import Logout from './components/Logout';
import LogGroups from './components/LogGroups';
import LogStreams from './components/LogStreams';
import LogStream from './components/LogStream';
import BillingAccounts from './components/BillingAccounts';
import Files from './components/Files';
import File from './components/File';
import Upload from './components/Upload';
import Download from './components/Download';
import RssCategories from './components/RssCategories';
import GenerateIntro from './components/GenerateIntro';
import SendScript from './components/SendScript';
import Template from './components/Template';

import './App.css';

const App = () => {
  return (
    <Router>
        <Routes>
            <Route path='/' element={<Login />} />
            <Route path='/login' element={<Login />} />
            <Route path='/home' element={<Home />} />
            <Route path='/billingaccounts' element={<BillingAccounts />} />
            <Route path='/customers' element={<Customers />} />
            <Route path='/customer/:id' element={<Customer />} />
            <Route path='/files/:id' element={<Files />} />
            <Route path='/file/:id' element={<File />} />
            <Route path='/upload/:id' element={<Upload />} />
            <Route path='/download/:id' element={<Download />} />
            <Route path='/logout' element={<Logout />} />
            <Route path='/loggroups' element={<LogGroups />} />
            <Route path='/loggroups/:id' element={<LogStreams />} />
            <Route path='/stream/:group/:stream' element={<LogStream />} />
            <Route path='/rsscategories' element={<RssCategories />} />
            <Route path='/generateintro' element={<GenerateIntro />} />
            <Route path='/sendscript' element={<SendScript />} />
            <Route path='/template' element={<Template />} />
          </Routes>
      </Router>
  );
};


export default App;

