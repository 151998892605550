import React, {useEffect, useState} from 'react';
import '../App.css';
import Cookies from "universal-cookie";
import Header from "./Header";
import axios from "axios";
import { SpinnerComponent } from 'react-element-spinner';
import {Link, useParams} from "react-router-dom";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
const moment = require("moment");

function LogStreams() {
    const [evs, setEvents] = useState([]);
    const [loading, setLoading] = useState(false);
    const {group, stream} = useParams();

    useEffect(() => {
        const cookies = new Cookies();
        const session_id = cookies.get('admin_session_id');

        const fetchData = async () => {
            setLoading(true);
            const feedData = await axios
                .get(`${process.env.REACT_APP_API_DOMAIN}/api/log/group/` + group + '/' + stream, {
                    withCredentials: true,
                    headers: {
                        Authorization: session_id
                    }
                });


            if(feedData.data) {
                setEvents(feedData.data.events);
            }

            setLoading(false);
        };

        void fetchData();
    }, [group, stream]);

    const rows = evs.map((e, k) => <tr><td>{moment(e.timestamp, "x").local().format("MM/DD/YYYY HH:mm:ss")}</td><td>{e.message}</td></tr>);

    return (
        <>
            <Header />
            <SpinnerComponent loading={loading} position="global" color="#498DFF" message={"Sit tight"}/>
            <div className="d-flex align-items-stretch">
                <Sidebar />
                <div className="page-content">
                    <div className="container-fluid py-2" style={{color: '#65676b'}}>
                        <Link to={"/home"}>Home</Link> > <Link to={"/loggroups"}>Log Groups</Link> > <Link to={"/loggroups/ " + group}>Streams</Link> > Stream
                    </div>
                    <div className="container-fluid mx-4 p-3" style={{background: '#2d3035', color: '#85888e'}}>
                        <h2>Events</h2>
                        <table className="table">
                            <tr>
                                <th scope="col">
                                    Event time
                                </th>
                                <th scope="col">
                                    Message
                                </th>
                            </tr>
                            {rows}
                        </table>
                    </div>
                    <Footer />
                </div>
            </div>
        </>
    );
}
export default LogStreams;

