import React, {useEffect, useState} from 'react';
import '../App.css';
import { useNavigate} from 'react-router-dom';
import CookieConsent from "react-cookie-consent";
import Cookies from "universal-cookie";
import axios from "axios";
import DOMPurify from "dompurify";
import {faTriangleExclamation} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { SpinnerComponent } from 'react-element-spinner';

function Login() {
    const navigate = useNavigate();
    const [errors, setErrors] = useState([]);
    const [isDisabled, setDisabled] = useState(false);
    const [user, setUser] = useState({
        username: '',
        password: '',
    });
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const cookies = new Cookies();
        const session_id = cookies.get('admin_session_id');

        (async () => {
            await axios
                .get(`${process.env.REACT_APP_API_DOMAIN}/api/adminsession/`,
                    {
                        withCredentials: true,
                        headers: {
                            Authorization: session_id
                        }
                    })
                .then((res) => {
                    navigate('/home');
                })
                .catch(function (err) {

                });
        })();
    }, [navigate]);

    const onChange = (e) => {
        setUser({...user, [e.target.name]: e.target.value});
    };

    const onSubmit = async (e) => {
        setLoading(true);
        e.preventDefault();
        setDisabled(true);
        const data = {
            username: user.username,
            password: user.password,
        };

        await axios
            .post(`${process.env.REACT_APP_API_DOMAIN}/api/admin/`, data)
            .then((res) => {
                const cookies = new Cookies();
                cookies.set('admin_session_id', res.data.session_id, {path: '/', domain: process.env.REACT_APP_COOKIE_DOMAIN});
                navigate('/home/');
            })
            .catch((error) => {
                setDisabled(false);

                if (error.response && error.response && error.response.data) {
                    setErrors(error.response.data.messages);
                } else {
                    setErrors([{'message': 'An error happened during the login process. Please try again', 'link': ''}]);
                }
            });
        setLoading(false);
    }

    //TODO: This can eventually be its own file, expand and reuse for account edits
    function Message(props) {
        return <div className="alert alert-danger"><FontAwesomeIcon icon={faTriangleExclamation} className='menuIcon'/>{DOMPurify.sanitize(props.message)}</div>;
    }

    const errorMessages = errors.map((error, k) => <Message message={error.message} link={error.link} key={k}/>);


    return (
        <section id="cover" className="min-vh-100">
            <div id="cover-caption">
                <div className="container">
                    <div className="row text-white">
                        <div className="col-xl-5 col-lg-6 col-md-8 col-sm-10 mx-auto text-center form p-4">
                            Admin Login
                            <div>{errorMessages}</div>
                            <div className="px-2">
                                <form onSubmit={onSubmit} className="justify-content-center">
                                    <SpinnerComponent loading={loading} position="global" color="#498DFF" message={"Sit tight"}/>
                                    <div className="form-group">
                                        <label className="sr-only">Username</label><br/><br/>
                                        <input type="text" name="username" className="form-control" placeholder="User Name" onChange={onChange} disabled={isDisabled}/>
                                    </div>
                                    <div className="form-group">
                                        <label className="sr-only">Password</label>
                                        <input type="password" name="password" placeholder="Password" className="form-control" onChange={onChange} disabled={isDisabled}/>
                                    </div>
                                    <button type="submit" className="btn btn-primary btn-lg" disabled={isDisabled}>Login</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <CookieConsent>This website uses cookies to enhance the user experience.</CookieConsent>
        </section>

    );
}

export default Login;

