import React, {useEffect, useState} from 'react';
import '../App.css';
import Cookies from "universal-cookie";
import Header from "./Header";
import { SpinnerComponent } from 'react-element-spinner';
import axios from "axios";
import {Link} from "react-router-dom";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
const moment = require("moment");

function BillingAccounts() {
    const [billingAccounts, setBillingAccounts] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const cookies = new Cookies();
        const session_id = cookies.get('admin_session_id');

        const fetchData = async () => {
            setLoading(true);
            const feedData = await axios
                .get(`${process.env.REACT_APP_API_DOMAIN}/api/billingaccount`, {
                    withCredentials: true,
                    headers: {
                        Authorization: session_id
                    }
                });
            if(feedData.data && feedData.data.length > 0) {
                setBillingAccounts(feedData.data);
            }

            setLoading(false);
        };
        void fetchData();

    }, []);

    const list = billingAccounts.map((account, k) => <tr>
        <td>{k}</td>
        <td>{account._vendorid}</td>
        <td>{account._description}</td>
        <td><Link to={"/customer/" + account._userid}>{account._userid}</Link></td>
        <td>{moment(account._created).local().format("MM/DD/YYYY HH:mm:ss")}</td>
    </tr>);

    return (
        <>
            <Header />
            <SpinnerComponent loading={loading} position="global" color="#498DFF" message={"Sit tight"}/>
            <div className="d-flex align-items-stretch">
                <Sidebar />
                <div className="page-content">
                    <div className="container-fluid py-2" style={{color: '#65676b'}}>
                        <Link to={"/home"}>Home</Link> > Stripe Records
                    </div>
                    <div className="container-fluid m-3 p-3" style={{background: '#2d3035', color: '#85888e'}}>
                        <p>Customer records found in Stripe. These are not necessarily paying customers.</p>
                        <table className="table">
                            <tr><th></th><th>Stripe Id</th><th>Stripe Description</th><th>Agency Next User</th><th>Created</th></tr>
                            {list}
                        </table>
                    </div>
                    <Footer />
                </div>
            </div>
        </>
    );
}
export default BillingAccounts;

