import React from 'react';
import '../App.css';


function Sidebar() {


    return (
        <>
            <nav id="sidebar">
                <span className="text-uppercase text-gray-600  m-3 px-2 heading mb-2">Main</span>
                <ul className="list-unstyled">
                    <li className="sidebar-item"><a className="sidebar-link" href="/customers"><span>Customers</span></a></li>
                    <li className="sidebar-item"><a className="sidebar-link" href="/sendscript"><span>Script Blast</span></a></li>
                </ul>
                <span className="text-uppercase text-gray-600  mx-3 px-2 heading mb-2">Tools</span>
                <ul className="list-unstyled">
                    <li className="sidebar-item"><a className="sidebar-link" href="/generateintro"><span>Generate Text</span></a></li>
                </ul>
                <span className="text-uppercase text-gray-600  mx-3 px-2 heading mb-2">System</span>
                <ul className="list-unstyled">
                    <li className="sidebar-item"><a className="sidebar-link" href="/loggroups"><span>AWS Logs</span></a></li>
                    <li className="sidebar-item"><a className="sidebar-link" href="/billingaccounts"><span>Stripe Records</span></a></li>
                </ul>
                <span className="text-uppercase text-gray-600  mx-3 px-2 heading mb-2">Bookmarks</span>
                <ul className="list-unstyled">
                    <li className="sidebar-item"><a className="sidebar-link" target={"_blank"} rel="noreferrer" href="https://trello.com/w/userworkspaced47d9e954ed6cd61f61e590bbcd17328"><span>Trello</span></a></li>
                    <li className="sidebar-item"><a className="sidebar-link" target={"_blank"} rel="noreferrer" href="https://www.stripe.com"><span>Stripe</span></a></li>
                    <li className="sidebar-item"><a className="sidebar-link" target={"_blank"} rel="noreferrer" href="https://agencynext.com"><span>Agency Next</span></a></li>
                    <li className="sidebar-item"><a className="sidebar-link" target={"_blank"} rel="noreferrer" href="https://veed.io"><span>Veed</span></a></li>
                    <li className="sidebar-item"><a className="sidebar-link" target={"_blank"} rel="noreferrer" href="https://account.postmarkapp.com/servers/12314503/templates"><span>Postmark</span></a></li>
                </ul>
            </nav>

        </>
    );
}

export default Sidebar;

